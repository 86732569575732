




















import {Component, Vue} from 'vue-property-decorator';
import {Compilation, createCompilation} from '@/types';
import {CompilationService} from '@/api';
import UTabs from '@/ui-lib/tabs/Tabs.vue';
import UTab from '@/ui-lib/tabs/Tab.vue';

@Component<CompilationView>({
  async beforeRouteEnter(to, from, next) {
    try {
      const id = parseInt(to.params.id, 10);
      const compilation = await CompilationService.item(id);

      next((vm) => {
        vm.compilation = compilation;
      });
    } catch (e) {
      next(e);
    }
  },

  metaInfo() {
    return {
      titleTemplate: `${this.compilation.name} - %s`,
    };
  },

  components: {
    UTabs,
    UTab,
  },
})
export default class CompilationView extends Vue {
  private compilation: Compilation = createCompilation();

  private get title(): string {
    return this.compilation.name;
  }

  private get tracksTabBadge(): string {
    return this.compilation.tracksCount.toString();
  }

  private tracksCountUpdate(newCount: number) {
    this.compilation.tracksCount = newCount
  }
}
